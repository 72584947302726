.container-profesional {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-flow: row wrap;
}

.container-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.section-background {
    background-color: #061A36;
    color: white;
}

.profesional-background {
    background-image: url("../../assets/square.svg");
    background-size: 35%;
    background-repeat: no-repeat;
    background-position: center top;
    background-color: #0B2347 !important;
    color: white;
}

.h-title {
    font-family: 'Francois One';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    text-transform: uppercase;
    color: #F2F2F2;
}

.u-text-small-green {
    color: #74B892 !important;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.u-text-small-white {
    font-size: 1.2rem;
    line-height: 1.4;
    font-weight: 300;
    color: white !important;
}

.u-title p {
    width: 50%;
    min-width: 40rem;
    margin: 0 auto;
    color: var(--dark-text);
}


@media (max-width: 600px) {
    .container-profesional{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
