.container-proposition {
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.container-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.section-background {
    background-color: #061A36;
    color: white;
    
}

.h-title {
    font-family: 'Francois One';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    text-transform: uppercase;
    color: #F2F2F2;
}

.u-text-small-green {
    color: #74B892 !important;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.u-text{
    display: flex;
    justify-content: center;
}
.u-text-small-white {
    font-size: 1.2rem;
    line-height: 1.4;
    font-weight: 300;
    color: white !important;
    
}

.u-title p {
    width: 50%;
    min-width: 40rem;
    margin: 0 auto;
    color: var(--dark-text);
}

.u-title-proposition {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 8rem;
}

