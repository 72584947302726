.header-bg {
  width: 100%;
  height: 50%;
  min-height: vh;
  background: #0a1930;
  background-image: url("./assets/background-sphere-2.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: left top;

}
body {    
    background-color: #061b38;
}
