@import url('https://fonts.googleapis.com/css2?family=Francois+One&display=swap');

#header {
  position: relative;
  padding-top: 3%;
}
.header {
  width: 100%;
  height: 100%;
  height: 250px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; 
  padding: 0px 100px;
}

.header .sm-title{
  /* Excelient */
  
  height: 23px;

  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  /* identical to box height */

  /* Brand green */

  color: #74B892;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.header h1 {
  /* Supporting your digital journey */

  font-family: 'Francois One';
  font-style: normal;
  font-weight: 400;
  
  font-size: 5.5rem;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--color-white);
  position: relative;
  padding-bottom: 0.5rem;
}

.header h1 span {
  display: block;
}

.long {
  white-space: nowrap;
}

.header-left p {
  margin: 0.5rem 0;
  color: var(--color-white);
  width: 40%;
  /* Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, */


  height: 72px;

  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 18px;

  /* Gray 4 */

  color: #BDBDBD;
}

.header-right img {
  width: 300%;
  object-position: -19ch -10em;

}

.floating-icon {
  background-color: var(--light-blue);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  cursor: pointer;
}

.floating-icon a {
  border: 1px solid #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mouse {
  transform: translateY(-25%);
  animation: bounce 0.8s ease infinite alternate;
}

@keyframes bounce {
  0% {
    transform: translateY(-25%);
  }
  100% {
    transform: translateY(25%);
  }
}

@media screen and (max-width: 769px) {
  .header h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  /*
  .header {
    flex-direction: column;
  }
  */
  .long {
    white-space:normal;
  }
  .header-left,
  .header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .header-left {
    margin-bottom: 4rem;
    width: 400px
  }

  .header h1::after {
    left: 50%;
    transform: translateX(-50%);
  }
  .header-right img {
    width: 80%;
  }
  
}
