@import url('https://fonts.googleapis.com/css2?family=Francois+One&display=swap');

.card-Container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    padding: 0px;
    margin-bottom: 2px;
    width: 100%;
    height: 390px;
    background: rgba(6, 26, 54, 0.8);
    border-radius: 30px;
}

.card-img-container {
    width: 45%;
    height: 90%;
    margin-top: 20px;
    margin-left: 20px;
}

.card-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    /* margin-top: 13px;
    margin-right: 20px; */
    gap: 35px;
    width: 45%;
    height: 75%;
    background: #0B2347;
    border-radius: 30px;
}

.card-img-container img {
    width: 100%;
    height: 100%;
}

.card-Container-reverse {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 2px;
    width: 100%;
    height: 390px;
    background: rgba(6, 26, 54, 0.8);
    border-radius: 30px;
}

.card-img-container-reverse {
    width: 45%;
    height: 90%;
    margin-top: 20px;
    margin-right: 20px;
}

.card-info-container-reverse {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    padding-left: 30px;
    /* margin-top: 20px;
    margin-left: 20px; */
    gap: 35px;
    width: 45%;
    height: 75%;
    background: #0B2347;
    border-radius: 30px;
}

.card-img-container-reverse img {
    width: 100%;
    height: 100%;
}

.card-info-title {
    font-family: 'Francois One';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    text-transform: uppercase;
    color: #F2F2F2;
}

.card-location-title {
    font-family: 'Francois One';
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 15px;
    color: #2283C2;
}

.div-location {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.span-description { 
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
}

.div-engagement {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.p-engagement {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
}

.icon{
    font-size: 12px;
    margin-right: 5px;
}


@media (min-width:200px) and (max-width: 800px) {
    .card-Container, .card-Container-reverse {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 70%;
        padding-bottom: 20px;

    }
    .card-info-container, .card-info-container-reverse {
        width: 90%;
        
    }
    .card-img-container, .card-img-container-reverse {
        margin-top: 10px;
        width: 80%;
        height: 100%;
    }
    .card-location-title{
        font-size:xx-large;
        font-weight: bold;
    }
    
}


@media (max-width: 400px) {
    .card-img-container, .card-img-container-reverse { 
        margin-left: 0px;
        margin-right: 0px;
    }
    .card-Container, .card-Container-reverse {
        margin-right: 0px;
    }
    .card-info-title {
        font-size: medium;
    }
    .card-location-title{
        font-size: large;
    }

    .span-description, .p-engagement, .span-description {
        font-size: small;
    }
}