.container-locations {
    width: 100%;
    height: 500px;
    background-image: url("../../assets/map.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.divButton {
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.u-text-small-green-locations {
    color: #74B892 !important;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.h-title-locations {
    font-family: 'Francois One';
    font-style: normal;
    font-weight: 400;
    font-size: 56px;
    line-height: 79px;
    text-align: center;
    text-transform: uppercase;
    color: #F2F2F2;
}

.u-text-small-white-locations{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: white !important;
}

.section-background3 {
    background-color: #0B2347;
    color: white;
}
