.profesional {
  border: 1px solid var(--light-blue);
  border-radius: 3px;
  height: 398px;
  width: 196.17px;
  margin-bottom: 3rem;
  box-shadow: var(--box-shadow);
}

.text-small-white {
  color: #BDBDBD;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  padding-top: 5px;
}

.background-professional {
  width: 196.17px;
  height: 398px;  
  border-radius: 24px;
}

.profesional-footer {
  font-family: 'Francois One';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  text-transform: uppercase;
  color: #2283C2;
}

.img-profesional {
  width: 196.17px;
  height: 398px;  
  border-radius: 24px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.containerTalent{
  margin: 10px;
}

@media (max-width: 800px) {
  .container{
    width:auto;
    padding: 6px;
  }

  .profesional {
    margin-bottom: 0px;
  }
}

@media (max-width: 400px) {
  .profesional-footer {
    padding: 5%;
  }
}