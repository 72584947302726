.services-bg {
    width: 100%;
    height: 100%;
    min-height: 200vh;
    background: #0a1930;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left top;
  
  }

  .section-bchgrnd {
    padding-top: 0px;
    background-clip:padding-box;
    background-color: #061A36;
    color: white;
}

.cardColor {
  background-color: #0B2347;
}

.center {
  width: 100%;
  height: 100%;

  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
  padding-top: 6rem;
  position: relative;
}

.container-bckgrnd{
  width: 100%;
  height: 100%;
  min-height: 250px;
  background-image: url("../../assets/square.svg");
  background-size: 35%;
  background-repeat: no-repeat;
  background-position: center top;
}

.h1-ourservices {
  height: auto !important;
}

.container-proposition-os {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  
  justify-content: space-evenly !important;
  padding: 50px 10px 10px 10px;
}

/* Text styles */
.h3-text-small {
  font-size: 22px;
  line-height: 1.4;
  font-weight: 300;
}

@media (min-width:100px) and (max-width: 800px) {
  
  .service-w {
    height: auto;
  }
  .container { 
    width: auto;
  }

  .container-bckgrnd {
    min-height: 100px;
  }

}