@import url('https://fonts.googleapis.com/css2?family=Francois+One&display=swap');


.header-container-geo {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 100%;
    padding: 7rem 0 10rem 0;
}

.title-small-green-geo {
    color: #74B892 !important;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.h-title-geo{
    font-family: 'Francois One';
    font-style: normal;
    font-weight: 400;
    font-size: 56px;
    line-height: 79px;
    text-align: center;
    text-transform: uppercase;
    color: #F2F2F2;
}

.description-geo{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #F2F2F2;
}

.cards-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white !important;
}

@media (max-width: 900px) {
    .header-container-geo {
        padding: 7rem 0 6rem 0;
    }
  }

  @media (max-width: 400px) {
    .header-container-geo {
        padding: 7rem 0 2rem 0;
    }
  }


