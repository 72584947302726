.container-proposition {
    justify-content: space-evenly !important;
    /* margin-top: -85px !important;  */
}

.container-section-home-title-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    font-family: 'Francois One';
    font-style: normal;
    font-weight: 400;
    font-size: 2.1rem;
    line-height: 1.4;
    text-transform: uppercase;
    color: var(--color-white);
    position: relative;
    padding-bottom: 0.5rem;
}

.container-section-home {
    margin-top: -105px !important;
}

.service {
    min-height: 300px;
    max-height: 700px;
    border: 1px solid gray;
    height: 350px !important;
}

.container-service {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.container-button {
    justify-content: center;
    text-transform: uppercase;

    display: flex;
    flex-direction: column;
    padding: 30px;
    margin-top: auto;
    align-self: center;
}

.section-background2 {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    background-clip:padding-box;
    background-color: rgba(6, 26, 54, 0.8);
    color: white;
}

.h-title {
    font-family: 'Francois One';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    text-transform: uppercase;
    color: #F2F2F2;
}

.u-text-small-green {
    color: #74B892 !important;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.u-text-small-white {
    font-size: 1.2rem;
    line-height: 1.4;
    font-weight: 300;
    color: white !important;
}

.u-title p {
    width: 50%;
    min-width: 40rem;
    margin: 0 auto;
    color: var(--dark-text);
}


@media screen and (max-width: 800px){
    .service {
        min-height: 300px !important;
        height: 370px !important;
    }
}
