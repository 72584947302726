.background-color {
  background: linear-gradient(180deg, rgba(6, 26, 54, 0.8) 0%, #0B2347 100%);
}

.container-diamond {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-image: url("../../assets/dot-square.svg");
  background-size: 95%;
  background-repeat: no-repeat;
  background-position: center right;
}

.container-card {
  background: linear-gradient(180deg, rgba(6, 26, 54, 0.8) 0%, #0B2347 100%);
}

.inner-content-title {
  margin: 1rem 2rem;
  border: 2px solid #fff;
  border-radius: 5px;
  width: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1.5rem;
}

.img-background {
  width: 373px;
  height: 583px;
  left: 0px;
  top: 0px;
  background: url("../../assets/placeholder.png"), #333333;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100px;
}

.inner-container {
  color: white;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 1rem;
}

.card-head {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.card-text {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: white !important;
  text-align: justify;
}

.u-text-small-white-locations{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: white !important;
}
.h-title {
  font-family: 'Francois One';
  font-style: normal;
  font-weight: 400;
  font-size: 56px !important;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.u-text-small-green {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #74B892;
}


section#download {
  background: linear-gradient(
    90deg,
    rgba(45, 165, 255, 1) 45%,
    rgba(1, 223, 245, 1) 100%
  );
  text-align: center;
  color: #fff;
}

.download h2 {
  font-size: 3rem;
  font-weight: 200;
  line-height: 1.4;
  text-transform: uppercase;
  margin: 1.5rem 0;
  position: relative;
  padding-bottom: 1rem;
}

.download h2::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 10%;
  height: 1px;
  background: var(--color-white);
  transform: translateX(-50%);
}
.download-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.card-footer-button { 
  padding-top: 50px;
}

@media screen and (max-width: 1000px) {
  .h-title {
    font-size: 46px !important;
  }
}

@media screen and (max-width: 600px) {
  .container-diamond {
    flex-direction: column;
    text-align: center;   
    height: 1073px;
  }
  .inner-container {
    max-width: 350px;
  }
  .card-footer-button {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .proposition-title{
    color: #2283C2;
  }

  .img-background {
    width: 357px;
    height: 583px !important;
    left: 0px;
    top: 0px;
    border-radius: 24px;
  }
}