  .container-headeraboutus {
    height: 400px;
    width: 900px;
    margin-right: 10%;
    margin-left: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 1rem;
  }
  
  .img-background-headeraboutus {
    width: 100%;
    height: 400px;
    left: 0px;
    top: 0px;
    background: url("../../assets/globedot.svg"), #333333;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .about-container {
    margin: 1rem 2rem;
    color: white;
    width: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 1rem;
  }

  .container-header-about{
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: #061a36 !important;
    max-height: 400px;
  }

  .about-section {
    padding: 0%;
  }

  @media screen and (max-width: 600px) {
    .about-container h2,p{
      max-width: 35rem;
    }

    .container-header-about {
      flex-direction: column;
      text-align: center;   
      /*height: 1073px;*/
    }

    .container-headeraboutus {
      max-width: 350px; 
    }

    .img-background-headeraboutus {
      width: 100%;
      height: 420.22px;
    }
    
  }