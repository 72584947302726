.service, .service-w {
  display: flex;
  flex-direction: column;

  border: 1px solid var(--light-blue);
  border-radius: 8%;

  width: 423px;
  height: 590px;

  margin: 2rem;
  box-shadow: var(--box-shadow);
  border-radius: 8%;
}

.service {
  background-color: #061A36;
}
.service-w {
  background-color: #0B2347;
}
.service-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-bottom: 1rem; */
}

.service-title h4 {
  font-family: 'Francois One';
  font-style: normal;
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  text-transform: uppercase;
}

.breakline {
  white-space: pre-line;
}

.service-title img{
  padding: 1.5rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  border-radius: 16%;
}

.icon-size{
  width: 23%;
}

.icon-size-wide {
  height: 95.94px;
  padding: 1.5rem;
}

.icon1{
  background-color: rgba(115, 184, 145, 0.24);
}

.icon2{
  background-color: rgb(34, 131, 194, 0.24);
}

.icon3{
  background-color: rgb(207, 176, 122, 0.24);
}

.proposition-title h4 {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
}

button.proposition-icons {
  border: 1px solid var(--color-grey);
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.proposition-index {
  font-family: 'Francois One';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 56px !important;
  line-height: 79px;
  color: #2283C2;
}

.service-answer-w {
  margin-top: 5%;
  margin-bottom: 5%;
  text-align: justify;
}

.service-answer {
  margin-top: 5%;
  margin-bottom: 5%;
  text-align: justify;
}

.smaller {
  font-size: 16px !important;
}

.service-links {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 15px;
  /* display: flex;
  flex-direction: column;
  margin-bottom: auto;
  align-self: flex-start; */
}

.service-links a {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  /* Brand blue */

  color: #74B892;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

}

.u-text-small-w {
  min-height: 170px;
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: 300;
}

/* @media screen and (max-width: 1200px) { 
  .service-links a {
    font-size: 1.4vw;
  }
} */



@media screen and (max-width: 800px) {
  .service, .service-w  {
    height: auto;
    max-height: none !important;
    min-height: 570px !important;
    width: 358px;
    padding: 20px !important;
  }

  .title-desc-services {
    width: 100%;
    font-size: 2.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .title-desc-services h4 {
    font-size: 2.5rem;
  }

  .u-text-small {
    font-size: 2.5vw;
  } 

  .service-answer, .service-answer-w { 
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .service-links a {
    font-size: 3vw;
  }
  
}

@media screen and (max-width: 600px) {

  .u-text-small-w { 
    max-width: 100% !important;
  }

  .service-answer, .service-answer-w { 
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .service-links {
    gap: 9px;
  }


  .service-links a {
    font-size: 15px;
  }

  .smaller {
    font-size: 14px !important;
  }
  
}