a {
  border-radius: 100px;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  text-decoration: none;
  margin-right: 1rem;
  transition: all 0.3s;
}
/* a:hover,
#nav-links-mobile a:hover {
  color: orangered;
} */
a.btn {
  box-shadow: 0px 0px 0px 11px rgba(34, 131, 194, 0.30);
  /* box-shadow: 2px 20px 2px -2px rgba(248, 9, 9, 0.925); */
  border-radius: 5px;
  padding: 5px 25px;
  font-weight: 500;
  cursor: pointer;
}

a.btn-dark {
  border: 1px solid rgba(1, 118, 191, 0.4);
  /* background: transparent; */
  border-radius: 30px;
  background: linear-gradient(0deg, #2283C2, #2283C2),
  linear-gradient(0deg, rgba(1, 118, 191, 0.4), rgba(1, 118, 191, 0.4));
}
a.btn-light {
  background: orangered;
  border: 2px solid transparent;
  color: #fff;
}


