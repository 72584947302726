@import url('https://fonts.googleapis.com/css2?family=Francois+One&display=swap');
.background-image{
    width: 100%;
    height: 850px;
    background: #0a1930;
    background-image: url("../../assets/contact-sphere.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 20% 80%;
}

.leblanc{
    height: 3%;
}

.container-card {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 40px;
    gap: 80px;   
    /* width: 100%; */
    height: 850px;
    background: linear-gradient(180deg, #061A36 0%, rgba(6, 26, 54, 0.76) 100%);
    border-radius: 24px;
    margin: 30px;
}

.title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    margin-bottom: 1rem !important;
}

.h-title {
    font-family: 'Francois One';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 50px;
    text-transform: uppercase;
}

.p-title {
    color: white !important;
    display: flex;
    align-items: start;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
}

.div-inputs-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    gap: 60px;
    width: 70%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.offices-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 40px;
    width: 30%;
    height: 575px !important;
    color: white;
    justify-content: center;
    align-items: center;
}

.form-contactus-fields {
    width: 400px;
    border: transparent;
    box-sizing: border-box;
    background-color: transparent;
    font-size: 20px;
    border-bottom: 1px solid #FFFFFF;
    color: white;
    border-radius: inherit;
    padding: 0;
}

input:focus{
    outline: none;
}

.form-container {
    display: flex;
    flex-direction: row;
    justify-items: start;
    width: 100%;
}

.office-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100px;
    background: #0B2347;
    border-radius: 24px;
    padding: 10px;
}

.office-container h1 {
    font-size: 15px;
}

.form-contactus {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.div-button-contactus{
    display: flex;
    flex-direction: column;
    align-items: center;
}

input[type=submit]{
    box-shadow: 0px 0px 0px 11px rgba(34, 131, 194, 0.30);
    padding: 5px 25px;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid rgba(1, 118, 191, 0.4);
    border-radius: 30px;
    background: linear-gradient(0deg, #2283C2, #2283C2),
    linear-gradient(0deg, rgba(1, 118, 191, 0.4), rgba(1, 118, 191, 0.4));
    width: 20rem;
    font-size: 16px;
}

  @media (max-width: 800px) {
    .form-container { 
        flex-direction: column;  
    }

    .form-container {
      flex-direction: column;
      height: 1350px;
      width: 90%
    }

    /* .office-container{
        width: 240px;
    } */

    input {
        width: 270px;
    }

    .div-inputs-container {
        justify-content: center;
    }

    .title {
        align-items: center;
    } 

    .p-title {
        width: 25rem !important;
        min-width: 25rem !important;
    }
  }

  @media (max-width: 900px) {
    .office-container {
        height: 145px;
    }
  }

  @media (max-width: 649px) {
    .form-contactus-fields{
        width: 100%;
        min-width: 100px;
    }

    input[type=submit]{
        min-width: 100px;
    }

    .div-inputs-container {
        width: 100%;
        justify-content: center;
    }

    .offices-container {
        width: 100% !important;
        align-items: center;
    }
    .office-container {
        align-items: center !important;
        height: 100px;
    }

    .container-card {
      flex-direction: column;
      height: 1200px;
    }

    .title {
        align-items: center;
    } 

    .p-title {
        width: 25rem !important;
        min-width: 25rem !important;
    }

    .background-image{
        height: 1200px;
    }
  }

  @media (max-width: 400px) {
    .div-inputs-container {
        padding: 0px;
    }

    input[type=submit]{
        width: 100%;
    }
  }