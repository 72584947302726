.proposition {
  border: 1px solid var(--light-blue);
  border-radius: 3px;
  width: 20%;
  min-height: 305px;
  max-width: 80rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: 2rem;
  box-shadow: var(--box-shadow);
  background-color: #0B2347;
  border-radius: 8%;
  height: 330px;

}

.breakline {
  white-space: pre-line;
  font-size: 12pt;
}

.proposition-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Francois One';
  font-style: normal;
  font-weight: 400;
  margin-top: 15px;
  /* padding-bottom: 1rem; */
}

.proposition-title h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
.proposition-answer{
  text-align: justify;
}

button.proposition-icons {
  border: 1px solid var(--color-grey);
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.proposition-index {
  font-family: 'Francois One';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 56px !important;
  line-height: 79px;
  color: #2283C2;
}
@media only screen and (min-width: 800px) and (max-width:950px ) {
  .proposition{
    width: 40%;
    min-height: 300px;
    height: 300px;
  }
}

@media only screen and (max-width:799px ) {
  .proposition{
    min-height: 250px;
    height: 250px;
  }
}

@media screen and (min-width:951px) and (max-width:1428px){
  .proposition{
    width: 29%;
    
  }
}

@media only screen and (max-width:349px ) {
  .proposition{
    min-height: none;
    height: auto;
  }
}
